<template>
    <div class="card-tail rakenskapsar" style="width: 800px;">
        <ModalExport :visible="modalAddVisible" :client-id="clientId" :financial-year="selectedYear" @close="modalAddVisible = false" />

        <el-table :data="tableData" v-loading="$waiting.is('loading')" :row-class-name="tableRowClassName">
            <el-table-column label="id" prop="financialYearId" width="80" />
            <el-table-column label="Start datum" width="100">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.startDate) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Slut datum">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.endDate) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Antal verifikat" prop="voucherCount" width="120" />
            <el-table-column label="Stängd" align="center" width="70">
                <template slot-scope="scope">
                    <el-tooltip transition="none" effect="light" :content="scope.row.isClosed === 1 ? 'Öppna år' : 'Stäng år'" placement="top" :enterable="false" :open-delay="500">
                        <el-button :disabled="!scope.row.isClosedEditable" @click="toggleCloseStatus(scope.row)" type="default" plain class="p-1">
                            <i v-if="scope.row.isClosed" class="far fa-lock-keyhole fa-fw text-base" />
                            <i v-else class="far fa-lock-open fa-fw text-base" />
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="IB" align="center" width="50">
                <template slot-scope="scope">
                    <el-tooltip transition="none" effect="light" :content="scope.row.openingBalanceStatus === 1 ? 'Aktivera' : 'Inaktivera'" placement="top" :enterable="false" :open-delay="500">
                        <el-button
                            v-if="scope.row.openingBalanceStatus"
                            :disabled="scope.row.isClosed || !scope.row.isOpeningBalanceEditable"
                            @click="toggleOpeningBalanceStatus(scope.row)"
                            type="default"
                            plain
                            class="p-1"
                        >
                            <i v-if="scope.row.openingBalanceStatus === 1" class="far fa-toggle-off fa-fw text-base" />
                            <i v-else class="far fa-toggle-on fa-fw text-base" />
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column width="200">
                <template slot-scope="scope">
                    <el-tooltip transition="none" effect="light" content="Välj räkenskapsår" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button @click="setRakenskapsar(scope.row)" type="default" plain class="p-1">
                            <i class="fal fa-calendar text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Ladda ner SIE4-fil" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button @click="downloadRakenskapsar(scope.row)" type="default" plain class="p-1">
                            <i class="fal fa-download text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Kopiera verifikat från tidigare bokföring" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button @click="copyRakenskapsar(scope.row.financialYearId)" type="default" plain class="p-1">
                            <i class="fal fa-copy text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Nummrera om verifikat i detta räkenskapsår" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button @click="reorderRakenskapsar(scope.row.financialYearId)" type="default" plain class="p-1">
                            <i class="fa fa-arrows text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Rensa alla verifikat i detta räkenskapsår" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button @click="deleteRakenskapsar(scope.row.financialYearId)" type="danger" plain class="p-1">
                            <i class="fal fa-trash-can text-base" />
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "../lopande.api";

export default {
    data() {
        return {
            tableData: [],
            modalAddVisible: false,
            selectedYear: null,
        };
    },

    components: {
        ModalExport: () => import("./LopandeRakenskapsarModalExport"),
    },

    props: {
        clientId: {
            type: String,
            default: "",
        },
    },

    watch: {
        clientId() {
            this.getAll();
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$waiting.start("loading");
            this.tableData = await Api.getRakenskapsar({ clientId: this.clientId });
            this.$waiting.end("loading");
        },

        async deleteRakenskapsar(id) {
            const confirm = await this.$dialog.title("Bekräftelse").confirm("Alla verifikat detta räkenskapsår kommer att raderas! Är du saker?");
            if (!confirm) return;

            const confirm2 = await this.$dialog.title("Bekräftelse").confirm("Sista varningen vill du avbryta raderingen?");
            if (confirm2) return;

            try {
                this.$waiting.start("loading");
                await Api.deleteRakenskapsar(this.clientId, id);
            } finally {
                this.$waiting.end("loading");
            }

            this.getAll();
        },
        async reorderRakenskapsar(id) {
            const confirm1 = await this.$dialog.title("Endast test (använd inte än!)").confirm("Alla verifikat detta räkenskapsår kommer att nummreras om! Är du saker?");
            if (!confirm1) return;

            const confirm2 = await this.$dialog.title("Bekräftelse").confirm("Sista varningen vill du avbryta ?");
            if (confirm2) return;

            try {
                this.$waiting.start("loading");
                await Api.reorderRakenskapsar(this.clientId, id);
            } finally {
                this.$waiting.end("loading");
            }

            this.getAll();
        },

        async downloadRakenskapsar(selectedYear) {
            this.selectedYear = selectedYear;
            this.modalAddVisible = true;
        },

        async copyRakenskapsar(id) {
            this.$waiting.start("loading");
            const response = await Api.copyRakenskapsar(this.clientId, id);
            if (response.status == 201) {
                this.$notify.success({ title: "Utfört", message: response.data });
                this.getAll();
            }
            this.$waiting.end("loading");
        },

        async setRakenskapsar(row) {
            const query = queryString.parse(location.search);
            query.startDate = moment(row.startDate).format("YYYY-MM-DD");
            query.endDate = moment(row.endDate).format("YYYY-MM-DD");
            this.$router.replace({ query }).catch(() => {});
            this.$emit("dateSelectionChanged", { customDate: true });
        },

        async toggleCloseStatus(row) {
            this.$waiting.start("loading");
            await Api.toggleCloseStatus({ clientId: this.clientId, financialYearId: row.financialYearId, closed: !row.isClosed });
            this.getAll();
            this.$waiting.end("loading");
        },

        async toggleOpeningBalanceStatus(row) {
            this.$waiting.start("loading");
            await Api.toggleOpeningBalanceStatus({ clientId: this.clientId, financialYearId: row.financialYearId, openingBalanceStatus: row.openingBalanceStatus === 1 ? 2 : 1 });
            this.getAll();
            this.$waiting.end("loading");
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        tableRowClassName({ row }) {
            if (row.isClosed) {
                return "selected-row";
            }
            return "";
        },
    },
};
</script>
<style>
.rakenskapsar .selected-row {
    background-color: #e1ffec;
}
</style>
